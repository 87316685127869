export default {
	body: {
		margin: 0,
		padding: 0,
	},
	".icon-link": {
		display: "none",
	},
	".with-overlay": {
		overflow: "hidden",
	},
};
